import "./Layout.css"

import classnames from "classnames"
import React, { MouseEvent } from "react"

import { observer } from "gather-browser-common/dist/src/public/frontendMobxUtils"

const spacingProps = [
  "margin",
  "marginLeft",
  "marginTop",
  "marginBottom",
  "marginRight",
  "padding",
  "paddingLeft",
  "paddingRight",
  "paddingTop",
  "paddingBottom",
  "borderRadius",
  "gap",
]

export interface PositionProps {
  position?: "static" | "relative" | "absolute" | "fixed" | "sticky"
  bottom?: string
  top?: string
  left?: string
  right?: string
}

export type ResizeCursorProps = "ew-resize" | "ns-resize" | "nesw-resize" | "nwse-resize"

// In the future, we can have a set of Gather specific colors.
interface StyleProps {
  color?: string
  backgroundColor?: string
  zIndex?: number
  overflow?: "auto" | "scroll" | "visible" | "hidden"
  overflowY?: "auto" | "scroll" | "visible" | "hidden"
  cursor?: "default" | "pointer" | "text" | "not-allowed" | "none" | ResizeCursorProps
  textAlign?: "right" | "left" | "center" | "justify"
  opacity?: number
  border?: string
  visibility?: "visible" | "hidden"
}

export interface SizeProps {
  width?: string
  height?: string
  minWidth?: string
  maxWidth?: string
  minHeight?: string
  maxHeight?: string
  borderRadius?: number | string
}

export interface MarginProps {
  margin?: "auto" | number
  marginLeft?: "auto" | number
  marginTop?: "auto" | number
  marginBottom?: "auto" | number
  marginRight?: "auto" | number
  marginX?: "auto" | number
  marginY?: "auto" | number
}

interface PaddingProps {
  padding?: number
  paddingLeft?: number
  paddingTop?: number
  paddingBottom?: number
  paddingRight?: number
  paddingX?: number
  paddingY?: number
}

export interface FlexProps {
  display?: "flex" | "block" | "none" | "inline-flex" | "inline-block" | "grid"
  flexDirection?: "row" | "column" | "column-reverse"
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "initial"
    | "inherit"
  alignItems?: "stretch" | "center" | "flex-start" | "flex-end" | "baseline" | "initial" | "inherit"
  flexBasis?: string
  flexWrap?: "wrap" | "nowrap" | "wrap-reverse"
  flexGrow?: number
  flexShrink?: number
  flex?: string
  gap?: number
}

export interface CenterProps {
  centerX?: boolean
  centerY?: boolean
}

export interface InteractionProps {
  pointerEvents?: "none" | "auto" | "inherit" | "initial" | "unset"
}

interface LayoutProps
  extends FlexProps,
    MarginProps,
    PaddingProps,
    SizeProps,
    StyleProps,
    PositionProps,
    InteractionProps,
    CenterProps {}

export interface Props extends LayoutProps {
  // Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any
  className?: string
  onClick?: (e: MouseEvent) => void
  onContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void
  onMouseDown?: (e: MouseEvent) => void
  onMouseOver?: (e: MouseEvent) => void
  onMouseLeave?: (e: MouseEvent) => void
  onScroll?: React.HTMLAttributes<HTMLDivElement>["onScroll"]
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void
  onDragLeave?: (e: React.DragEvent<HTMLDivElement>) => void
  onDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void
  tabIndex?: number
  role?: React.AriaRole
  testId?: string
}

export const SPACING_SCALE = 4
export const scale = (n: number): string => `${n * SPACING_SCALE}px`

const Layout = observer(
  React.forwardRef<HTMLDivElement, Props>(function Layout(props, ref) {
    const {
      display = "flex",
      children,
      className,
      onClick,
      onContextMenu,
      onMouseDown,
      onMouseLeave,
      onMouseOver,
      onKeyDown,
      onDragStart,
      onDragLeave,
      onDragEnd,
      onScroll,
      centerX,
      centerY,
      role,
      testId,
    } = props

    //  TODO: Filter out non-styles.
    // Lint warning auto-ignored when enabling the no-explicit-any rule. Fix this the next time this code is edited! TODO: @ENG-4294 Clean these up! See the linear task for guidance on how to do so.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const styles: any = {}
    Object.entries(props).forEach(([name, value]) => {
      if (value != null) {
        styles[name] = value
      }
    })

    if (props.marginX) {
      styles.marginLeft = props.marginX
      styles.marginRight = props.marginX
    }

    if (props.marginY) {
      styles.marginTop = props.marginY
      styles.marginBottom = props.marginY
    }

    if (props.paddingX) {
      styles.paddingLeft = props.paddingX
      styles.paddingRight = props.paddingX
    }

    if (props.paddingY) {
      styles.paddingTop = props.paddingY
      styles.paddingBottom = props.paddingY
    }

    if (props.border) {
      styles.border = props.border
    }

    if (props.pointerEvents) {
      styles.pointerEvents = props.pointerEvents
    }

    // Resolve linear space system.
    spacingProps.forEach((name) => {
      if (styles.hasOwnProperty(name) && typeof styles[name] === "number") {
        styles[name] = scale(styles[name])
      }
    })

    return (
      <div
        className={classnames("Layout", className, {
          "center-x": centerX,
          "center-y": centerY,
        })}
        style={{
          display,
          ...styles,
        }}
        onClick={onClick}
        onContextMenu={onContextMenu}
        onMouseDown={onMouseDown}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        onKeyDown={onKeyDown}
        onDragStart={onDragStart}
        onDragLeave={onDragLeave}
        onDragEnd={onDragEnd}
        onScroll={onScroll}
        ref={ref}
        tabIndex={props.tabIndex}
        role={role}
        data-testid={testId}
      >
        {children}
      </div>
    )
  }),
)

export default Layout
