import { blackenColor, COLORS, colorWithAlpha, whitenColor } from "./colors"

export const LIGHT_THEME = {
  themeName: "LIGHT_THEME",
  button: {
    primary: {
      default: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: COLORS.GREEN,
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: blackenColor(COLORS.GREEN, 10),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: whitenColor(COLORS.GREEN, 40),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: whitenColor(COLORS.GREEN, 30),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: COLORS.GREEN,
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: blackenColor(COLORS.GREEN, 10),
        borderColor: "transparent",
      },
    },
    secondary: {
      default: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: "transparent",
        borderColor: COLORS.DARK2,
      },
      active: {
        foregroundColor: blackenColor(COLORS.DARK2, 10),
        backgroundColor: "transparent",
        borderColor: blackenColor(COLORS.DARK2, 10),
      },
      focus: {
        foregroundColor: whitenColor(COLORS.DARK2, 40),
        backgroundColor: "transparent",
        borderColor: whitenColor(COLORS.DARK2, 40),
      },
      hover: {
        foregroundColor: whitenColor(COLORS.DARK2, 30),
        backgroundColor: "transparent",
        borderColor: whitenColor(COLORS.DARK2, 30),
      },
      disabled: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: "transparent",
        borderColor: COLORS.DARK2,
      },
      selected: {
        foregroundColor: blackenColor(COLORS.DARK2, 10),
        backgroundColor: "transparent",
        borderColor: blackenColor(COLORS.DARK2, 10),
      },
    },
    tertiary: {
      default: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: COLORS.BLUE4,
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(COLORS.BLUE4, 10),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: whitenColor(COLORS.BLUE4, 30),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: whitenColor(COLORS.BLUE4, 20),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: COLORS.BLUE4,
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(COLORS.BLUE4, 10),
        borderColor: "transparent",
      },
    },
    "low-key": {
      default: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: COLORS.GRAY2,
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: blackenColor(COLORS.GRAY2, 10),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: whitenColor(COLORS.GRAY2, 30),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: whitenColor(COLORS.GRAY2, 20),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: COLORS.GRAY2,
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: blackenColor(COLORS.GRAY2, 10),
        borderColor: "transparent",
      },
    },
    ghost: {
      default: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: colorWithAlpha(COLORS.GRAY2, 0.4),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: colorWithAlpha(COLORS.GRAY2, 0.6),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: colorWithAlpha(COLORS.GRAY2, 0.3),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: colorWithAlpha(COLORS.GRAY2, 0.4),
        borderColor: "transparent",
      },
    },
    danger: {
      default: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: COLORS.RED2,
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(COLORS.RED2, 10),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: whitenColor(COLORS.RED2, 30),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: whitenColor(COLORS.RED2, 20),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: COLORS.RED2,
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(COLORS.RED2, 10),
        borderColor: "transparent",
      },
    },
    "danger-secondary": {
      default: {
        foregroundColor: COLORS.RED2,
        backgroundColor: "transparent",
        borderColor: COLORS.RED2,
      },
      active: {
        foregroundColor: blackenColor(COLORS.RED2, 10),
        backgroundColor: "transparent",
        borderColor: blackenColor(COLORS.RED2, 10),
      },
      focus: {
        foregroundColor: whitenColor(COLORS.RED2, 30),
        backgroundColor: "transparent",
        borderColor: whitenColor(COLORS.RED2, 30),
      },
      hover: {
        foregroundColor: whitenColor(COLORS.RED2, 20),
        backgroundColor: "transparent",
        borderColor: whitenColor(COLORS.RED2, 20),
      },
      disabled: {
        foregroundColor: COLORS.RED2,
        backgroundColor: "transparent",
        borderColor: COLORS.RED2,
      },
      selected: {
        foregroundColor: blackenColor(COLORS.RED2, 10),
        backgroundColor: "transparent",
        borderColor: blackenColor(COLORS.RED2, 10),
      },
    },
    "inline-text": {
      default: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
    },
    "inline-text-danger": {
      default: {
        foregroundColor: COLORS.RED2,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      active: {
        foregroundColor: blackenColor(COLORS.RED2, 10),
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: blackenColor(COLORS.RED2, 30),
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: blackenColor(COLORS.RED2, 20),
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.RED2,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.RED2,
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
    },
    overlay: {
      default: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderColor: "rgba(255, 255, 255, 0.1)",
      },
      active: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        borderColor: "rgba(255, 255, 255, 0.4)",
      },
      focus: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        borderColor: "rgba(255, 255, 255, 0.4)",
      },
      hover: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        borderColor: "rgba(255, 255, 255, 0.4)",
      },
      disabled: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        borderColor: "rgba(255, 255, 255, 0.4)",
      },
      selected: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        borderColor: "rgba(255, 255, 255, 0.4)",
      },
    },
    white: {
      default: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: COLORS.GRAY0,
        borderColor: COLORS.GRAY0,
      },
      active: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: blackenColor(COLORS.GRAY0, 10),
        borderColor: blackenColor(COLORS.GRAY0, 10),
      },
      focus: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: blackenColor(COLORS.GRAY0, 10),
        borderColor: blackenColor(COLORS.GRAY0, 10),
      },
      hover: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: blackenColor(COLORS.GRAY0, 10),
        borderColor: blackenColor(COLORS.GRAY0, 10),
      },
      disabled: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: COLORS.GRAY0,
        borderColor: COLORS.GRAY0,
      },
      selected: {
        foregroundColor: COLORS.DARK2,
        backgroundColor: blackenColor(COLORS.GRAY0, 10),
        borderColor: blackenColor(COLORS.GRAY0, 10),
      },
    },
    "insights-primary": {
      default: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: COLORS.BLUE4,
        borderColor: "transparent",
        background: "linear-gradient(180deg, #7b67ea 0%, #5f4dc5 100%)",
      },
      active: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(COLORS.BLUE4, 10),
        borderColor: "transparent",
        background: "linear-gradient(180deg, #7b67ea 0%, #5f4dc5 100%)",
      },
      focus: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: whitenColor(COLORS.BLUE4, 30),
        borderColor: "transparent",
        background: "linear-gradient(180deg, #7b67ea 0%, #5f4dc5 100%)",
      },
      hover: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: whitenColor(COLORS.BLUE4, 20),
        borderColor: "transparent",
        background: "linear-gradient(180deg, #7b67ea 0%, #5f4dc5 100%)",
      },
      disabled: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: COLORS.BLUE4,
        borderColor: "transparent",
        background: "linear-gradient(180deg, #7b67ea 0%, #5f4dc5 100%)",
      },
      selected: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(COLORS.BLUE4, 10),
        borderColor: "transparent",
        background: "linear-gradient(180deg, #7b67ea 0%, #5f4dc5 100%)",
      },
    },
    "insights-secondary": {
      default: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.2),
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(colorWithAlpha(COLORS.WHITE, 0.2), 10),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: whitenColor(colorWithAlpha(COLORS.WHITE, 0.2), 30),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: whitenColor(colorWithAlpha(COLORS.WHITE, 0.2), 20),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.2),
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.GRAY0,
        backgroundColor: blackenColor(colorWithAlpha(COLORS.WHITE, 0.2), 10),
        borderColor: "transparent",
      },
    },
    "insights-tertiary": {
      default: {
        foregroundColor: colorWithAlpha(COLORS.BLACK, 0.6),
        backgroundColor: colorWithAlpha(COLORS.BLACK, 0.1),
        borderColor: "transparent",
      },
      active: {
        foregroundColor: colorWithAlpha(COLORS.BLACK, 0.6),
        backgroundColor: colorWithAlpha(whitenColor(COLORS.BLACK, 20), 0.1),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: colorWithAlpha(COLORS.BLACK, 0.6),
        backgroundColor: colorWithAlpha(whitenColor(COLORS.BLACK, 40), 0.1),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: colorWithAlpha(COLORS.BLACK, 0.6),
        backgroundColor: colorWithAlpha(whitenColor(COLORS.BLACK, 30), 0.1),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: colorWithAlpha(COLORS.BLACK, 0.6),
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.2),
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: colorWithAlpha(COLORS.BLACK, 0.6),
        backgroundColor: colorWithAlpha(whitenColor(COLORS.BLACK, 20), 0.1),
        borderColor: "transparent",
      },
    },
    setting: {
      default: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.2),
        borderColor: "transparent",
      },
      active: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.1),
        borderColor: "transparent",
      },
      focus: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.1),
        borderColor: "transparent",
      },
      hover: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.15),
        borderColor: "transparent",
      },
      disabled: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.1),
        borderColor: "transparent",
      },
      selected: {
        foregroundColor: COLORS.WHITE,
        backgroundColor: colorWithAlpha(COLORS.WHITE, 0.1),
        borderColor: "transparent",
      },
    },
  },
  switch: {
    standard: {
      checked: {
        backgroundColor: COLORS.GREEN,
      },
      unchecked: {
        backgroundColor: COLORS.DARK0,
      },
      disabled: {
        backgroundColor: COLORS.GRAY3,
      },
      loading: {
        backgroundColor: COLORS.GRAY3,
      },
    },
    monotone: {
      checked: {
        backgroundColor: COLORS.BLUE4,
      },
      unchecked: {
        backgroundColor: COLORS.BLUE4,
      },
      disabled: {
        backgroundColor: COLORS.GRAY3,
      },
      loading: {
        backgroundColor: COLORS.GRAY3,
      },
    },
  },
  textInput: {
    borderColor: {
      default: COLORS.GRAY4,
      focus: COLORS.DARK4,
      hover: "#7B7B7B",
      error: COLORS.RED1,
    },
    chip: {
      defaultChipColor: COLORS.GRAY2,
      chipTextColor: COLORS.DARK2,
      defaultChipBorder: COLORS.GRAY2,
    },
    iconColor: COLORS.DARK4,
    labelTextColor: COLORS.DARK0,
    textColor: COLORS.DARK4,
    placeholderTextColor: "#868686",
    errorTextColor: COLORS.RED1,
  },
  text: {
    primary: COLORS.DARK4,
    secondary: COLORS.GRAY3,
    fontFamily: "DM Sans, sans-serif",
  },
  icon: COLORS.DARK4,
  mapmaker: {
    // Just for now :sweat:
    colorSelect: {
      selectedBackground: COLORS.GRAY3,
    },
  },
}
