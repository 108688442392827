/**
 * This file serves as the pre-render setup for the `app` and plays a critical role in initializing essential configurations.
 * It's crucial to maintain clear separation between this file and `gather-browser/src/app.tsx` when making changes or additions.
 * The same should be considered when altering any of the imports.
 *
 * Notable limitation:
 * This file performs a one-time operation `setupMessages` to populate translated messages, which must occur before app rendering.
 * Messages imported before this operation will fail to apply, resulting in the error message: `t() was called before setupMessages() for key ${key}`
 */

import "./fonts/visitor1.ttf"
// the order of these css imports is vital to displaying fonts properly
import "./reset.css"
import "./main.css"
import "./utils/console"

import { MetricName } from "hooks/useDurationMetric"
import React from "react"
import { createRoot } from "react-dom/client"
import { buildErrorContext } from "utils/console"
import { Logger } from "utils/Logger"
import { isRecordingMode } from "utils/recordingClient"
import { registerGatherVideoClientGlobals } from "utils/videoSystem"

import { newRelicManager } from "gather-browser-newrelic-manager/dist/src/public/newRelicManager"
import { asyncRetry } from "gather-common/dist/src/public/asyncRetry"
import { just, lazily } from "gather-common-including-video/dist/src/public/fpHelpers"
import { setupMessages } from "gather-i18n/dist/src/public/t"
import { LocaleResource } from "gather-i18n/dist/src/public/types/types"
import { monitorClientStatus } from "src/utils/clientStatus"
import { getLocaleFilename } from "./i18n/i18nSharedUtils"
import { locale } from "./i18n/i18nUtils"
import RootRouteLoadingFallback from "./routes/RootRouteLoadingFallback"

declare global {
  interface Window {
    BUILD_TIMESTAMP: string
  }
}

window.BUILD_TIMESTAMP = BUILD_TIMESTAMP

newRelicManager.start()

// releaseId and releaseName follow the same pattern found in upload-source-maps.ts
newRelicManager.addRelease(`gather-browser-${COMMIT_HASH}`, COMMIT_HASH)
newRelicManager.setCustomAttribute("isRecordingClient", isRecordingMode() ? "true" : "false")

registerGatherVideoClientGlobals()

monitorClientStatus()

const RETRY_CONFIG = { maxRetries: 3, delayMs: 3000 }

// Invoke it immediately so to start fetching early
const resource: Promise<LocaleResource> = asyncRetry(
  // We default to `en-US`
  // commit hash is used for uniqueness across builds
  () => fetch(getLocaleFilename(locale, COMMIT_HASH)).then((res) => res.json()),
  RETRY_CONFIG,
)

export const loadApp = (initializeFn: () => Promise<{}>) => {
  // We have to specify loading text here because i18n hasn't loaded it. It will always display
  // in English.
  renderComponentAtRoot(<RootRouteLoadingFallback loadingText="Loading..." />)
  ;(async () => {
    try {
      // Set up `messages` (localized strings) before loading the app itself so to
      // properly output localized Dynamic strings (in React's `render`) AND Static strings
      // (constants outside React)
      setupMessages(await resource)

      await asyncRetry(initializeFn, RETRY_CONFIG).then(() => {
        // Measure from 0 to app.tsx loaded
        const measure = performance.measure(MetricName.AppLoaded)

        newRelicManager.addPageActionReliably(MetricName.AppLoaded, {
          duration: Math.floor(measure.duration),
          startTime: measure.startTime,
        })
      })
    } catch (error) {
      Logger.error("Failed to load app root", buildErrorContext(error))
      renderComponentAtRoot(
        <RootRouteLoadingFallback loadingText="An error occurred. Please check your network." />,
      )
    }
  })()
}

const getRoot = lazily(() => {
  const rootElement = just(document.getElementById("root"), "Could not find React root")
  return createRoot(rootElement)
})

export const renderComponentAtRoot = (component: React.ReactNode) => {
  const root = getRoot()
  root.render(component)
}
