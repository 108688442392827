export interface TextProps {
  fontWeight: 700 | 500 | 400
  fontSize: number
  lineHeight: number
  letterSpacing?: number
  isCapitalized?: boolean
}

export type TextKind =
  | "huge"
  | "big"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "subtitle1"
  | "subtitle2"
  | "body"
  | "body1"
  | "body2"
  | "body3"
  | "body4"
  | "body5"
  | "caption1"
  | "caption2"
  | "caption3"
  | "caption4"

export const TEXT_MAP: { [key in TextKind]: TextProps } = {
  huge: {
    fontWeight: 700,
    fontSize: 36,
    lineHeight: 42,
  },
  big: {
    fontWeight: 700,
    fontSize: 26,
    lineHeight: 34,
  },
  h1: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: 26,
  },
  h2: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 24,
  },
  h3: {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: 20,
  },
  h4: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 18,
  },
  h5: {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 15,
  },
  subtitle1: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 18,
    letterSpacing: 0.03,
    isCapitalized: true,
  },
  subtitle2: {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: 0.03,
    isCapitalized: true,
  },
  body: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 20,
  },
  body1: {
    fontWeight: 500,
    fontSize: 15,
    lineHeight: 20,
  },
  body2: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 18,
  },
  body3: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 18,
  },
  body4: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 18,
  },
  body5: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: 17,
  },
  caption1: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: 17,
  },
  caption2: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 17,
  },
  caption3: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 16,
  },
  caption4: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 17,
  },
}
