// Due to https://github.com/microsoft/TypeScript/issues/47663
// we're handling all the React operations in `gather-browser` instead of `gather-i18n`

import React from "react"
import { ErrorContext } from "utils/console"

import { switchEnv } from "gather-env-config/dist/src/public/env"
import { t as i18nT } from "gather-i18n/dist/src/public/t"
import { underline } from "src/styles/utils/utils"
import { Logger } from "src/utils/Logger"

// A centralized way to format common tags, these are also ignored by the ESLint rule `@gathertown/i18n-enforce-placeholders` in `.eslintrc.cjs`
const defaultRichTextElements = {
  b: (chunks: React.ReactNode[]) => <strong>{chunks}</strong>,
  br: () => <br />,
  u: (chunks: React.ReactNode[]) => <span css={underline}>{chunks}</span>,
  gt: () => <>{">"}</>,
  // Press `X` to
  X: () => (
    <svg
      viewBox="-2 -2 104 104"
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em"
      style={{ margin: "0.1em 0.35em" }}
    >
      <rect x="0" width="100" height="100" rx="12" fill="#fff" stroke="#000" strokeWidth="8" />
      <text
        x="48%"
        y="52%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="#000"
        fontWeight="bold"
        fontSize="72"
        fontFamily="'DM Sans', sans-serif"
        // eslint-disable-next-line @gathertown/no-literal-string-in-jsx
        aria-label="X"
        // eslint-disable-next-line @gathertown/no-literal-string-in-jsx
      >
        X
      </text>
    </svg>
  ),
}

/**
 * More about syntax and best practices: https://www.notion.so/gathertown/i18n-guide-b4ffc60ffdb6450b9f11dd7025a71b83
 * @param key a unique key for the message, or the message itself while on local webpack dev-server, that looks up for the translated string within source messages (`locales/*.json`)
 * @param variables object providing variables for interpolated string (`Hello {username}`) or replacements for tags (Hello <b>Riley</b>)
 * @param _metadata metadata that is sent to Translation Management Software, useful for providing translation context to translators with `notes`
 * @returns translated content for the current locale
 */
const t = (
  key: string,
  variables?: Record<string, unknown | ((chunks: React.ReactNode[]) => JSX.Element)> | null,
  _metadata?: Record<string | "notes", string>,
) => {
  const result = i18nT(key, {
    ...defaultRichTextElements,
    ...variables,
  })
  // Handles missing unique "keys" for children
  return typeof result === "string" ? result : React.Children.map(result, (c) => <>{c}</>)
}

export default t

// Refine `t()` wrapper's return as string, for attributes like `placeholder`
const tAsString = (result: Translated) => {
  if (typeof result !== "string") {
    switchEnv({
      test: null,
      local: () =>
        Logger.error(
          `t() was called with wrong format for key`,
          new ErrorContext({ result: result.toString() }),
        ),
      dev: null,
      staging: null,
      prod: null,
    })

    return "wrong key"
  }
  return result
}

// Refine `t()` wrapper's return as array
const tAsArray = (result: Translated) => (typeof result === "string" ? [result] : result)

export { tAsArray, tAsString }

export type Translated = ReturnType<typeof t>
