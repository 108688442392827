import ModalBackdrop from "components/ModalBackdrop";
import Layout from "components/ui/Layout";
import Text from "components/ui/Text";
import React from "react";
import { StyledRotatingLoaderContainer } from "styles/utils/utils";

import { observer } from "gather-browser-common/dist/src/public/frontendMobxUtils";
import t, { Translated } from "src/i18n/t";
import { COLORS } from "src/styles/themes/colors";

interface Props {
  loadingText?: Translated;
}

const LoadingScreen: React.FC<Props> = observer(function LoadingScreen({ loadingText }) {
  return (
    <ModalBackdrop zIndex={600}>
      <Layout
      centerX
      centerY
      padding={10}
      flexDirection="column"
      position="fixed"
      alignItems="center"
      gap={6}>

        <StyledRotatingLoaderContainer src="/images/spinner.png" />
        <Text kind="h3" color={COLORS.GRAY2}>
          {loadingText ? loadingText : t("b04ba49")}
        </Text>
      </Layout>
    </ModalBackdrop>);

});

export default LoadingScreen;