import { updateUserData } from "api/users/data"
import { DateTime, Settings } from "luxon"
import { Logger } from "utils/Logger"

import {
  getNavigatorLanguage,
  getSelectedLanguageSetting,
} from "gather-browser-common/dist/src/public/i18n"
import {
  LocalPreferenceKeys,
  localPreferences,
} from "gather-browser-common/dist/src/public/LocalPreferences"
import { logMetricsEvent } from "gather-browser-common/dist/src/public/metrics/utils"
import { DesktopSetting } from "gather-electron-interop/dist/src/public/desktopSettings"
import { switchEnv } from "gather-env-config/dist/src/public/env"
import {
  MetricsEventName,
  SpaceEventProperties,
} from "gather-http-common/dist/src/public/metrics/constants"
import {
  BROWSER_SUPPORTED_LOCALES,
  DEFAULT_LOCALE,
  enablePseudoLocalization,
} from "gather-i18n/dist/src/public/utils/constants"
import { localeMatcher } from "gather-i18n/dist/src/public/utils/utils"
import ElectronInterop from "../ElectronInterop"

const selectedLanguage = getSelectedLanguageSetting()
const navigatorLanguage = getNavigatorLanguage()

// Given a set of locales application has translations for and the set of locales a user requests, find the best matching locales.
const matchedLocale = (locale: string) =>
  localeMatcher([locale], BROWSER_SUPPORTED_LOCALES, DEFAULT_LOCALE)

export const updateLocalSelectedLanguage = (option: string) => {
  Settings.defaultLocale = option
  localPreferences.set(LocalPreferenceKeys.SelectedLanguage, option)
  ElectronInterop?.updateDesktopSetting?.(DesktopSetting.selectedLanguage, option)
}

/**
 * @param option the selected language
 * @param switchLanguageSource which action was performed to switch
 * @param persist whether to persist this setting on the user record
 */
export const switchPreferredLanguage = async (
  option: string,
  switchLanguageSource: SpaceEventProperties["switchLanguageSource"],
  persist = true,
) => {
  if (persist) {
    await updateUserData({ selectedLanguage: option })
  }
  updateLocalSelectedLanguage(option)

  // We don't want to log Automatic changes to Amplitude
  if (switchLanguageSource !== "Automatic") {
    logMetricsEvent(MetricsEventName.SETTING_CHANGED, {
      settingName: "Language",
      selectedLanguage: option,
      navigatorLanguage,
      autoSwitch: false,
      switchLanguageSource,
    })
  }
}

// This should be consistent with getSelectedOrRawLocale in gather-browser-common/src/i18n
const initLocale = () => {
  if (selectedLanguage) return matchedLocale(selectedLanguage)

  // Automatically switch to supported languages, does not persist it yet
  if (navigatorLanguage) {
    const matchedNavigatorLanguage = matchedLocale(navigatorLanguage)
    try {
      switchPreferredLanguage(matchedNavigatorLanguage, "Automatic", false)
    } catch (error) {
      Logger.error("Unable to persist automatically switched language")
    }
    return matchedNavigatorLanguage
  }

  return DEFAULT_LOCALE
}

export const getLocale = () =>
  switchEnv({
    test: () => (enablePseudoLocalization ? initLocale() : DEFAULT_LOCALE),
    local: initLocale,
    dev: initLocale,
    staging: initLocale,
    prod: initLocale,
  })

export const locale = getLocale()

export const localeIso = locale.split("-")[0]

// Setup `Luxon` early so to cover all instances
Settings.defaultLocale = locale

/**
 * Based on the timezone for the user's device, derive the likely language of the user. Return true if Gather supports this language, and false othwerwise. Does not apply to English, as there's an implicit assumption that English is supported (and this function more intended to determine the "new" languages Gather supports).
 *
 * For a more robust approach, we might look at the user's IP for coordinates. But for a simple announcement modal, this is probably good enough.
 */
export function doesGatherSupportLanguageForUsersTimezone() {
  const timeZone = DateTime.local().zoneName

  const brazilTimeZones = [
    "America/Rio_Branco",
    "America/Eirunepe",
    "America/Porto_Acre",
    "America/Manaus",
    "America/Boa_Vista",
    "America/Porto_Velho",
    "America/Campo_Grande",
    "America/Cuiaba",
    "America/Sao_Paulo",
    "America/Belem",
    "America/Fortaleza",
    "America/Recife",
    "America/Araguaina",
    "America/Maceio",
    "America/Bahia",
    "America/Noronha",
  ]
  const japanTimeZones = ["Asia/Tokyo"]

  const timezoneIsBrazil = brazilTimeZones.includes(timeZone)
  const timezoneIsJapan = japanTimeZones.includes(timeZone)
  return timezoneIsBrazil || timezoneIsJapan
}
