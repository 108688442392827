import "react" // TODO [Rebuild] remove once upgraded past TS 5.3.3

import { css, keyframes } from "@emotion/react"
import styled from "@emotion/styled"
import { media } from "styles/breakpoints/MediaQuery"
import { COLORS } from "styles/themes/colors"

import { STARS_IMAGE, STARS_IMAGE_MOBILE } from "gather-common/dist/src/public/constants"
import { isNil } from "gather-common-including-video/dist/src/public/fpHelpers"

export const SCROLLBAR_WIDTH = 8

// NOTE: If we ever decide to try adding padding to our scrollbar thumbs, see this PR for how to do so: https://github.com/gathertown/gather-town/pull/11725
// When first implemented, it proved to break too many existing scrollable containers, so a larger effort is necessary — see here for details: https://linear.app/gather-town/issue/APP-1420#comment-405ed26e
export const styledScrollbar = css`
  /* Non-Firefox browsers (they don't have the properties above, so they need vendor prefixes) */

  ::-webkit-scrollbar {
    width: ${SCROLLBAR_WIDTH}px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 1000px;

    /* Note that this is in the non-Firefox browsers section — Firefox doesn't have support to change the scrollbar color on hover 😕 */

    &:hover {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  /* Firefox browsers */
  scrollbar-color: rgba(255, 255, 255, 0.3);
  scrollbar-width: ${SCROLLBAR_WIDTH}px;
`

export const styledScrollbarLight = css`
  /* Non-Firefox browsers (they don't have the properties above, so they need vendor prefixes) */

  ::-webkit-scrollbar {
    width: ${SCROLLBAR_WIDTH}px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 1000px;

    /* Note that this is in the non-Firefox browsers section — Firefox doesn't have support to change the scrollbar color on hover 😕 */

    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  /* Firefox browsers */
  scrollbar-color: rgba(0, 0, 0, 0.3);
  scrollbar-width: ${SCROLLBAR_WIDTH}px;
`

export const underline = css`
  text-decoration: underline;
`

export const italic = css`
  font-style: italic;
`

export const bold = css`
  font-weight: 700 !important;
`

export const noWrap = css`
  white-space: nowrap;
`

export const centered = css`
  text-align: center;
`

export const textOverflowEllipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const modalShadowValue = "0px 10px 25px rgba(0, 0, 0, 0.55)"

export const modalShadow = css`
  box-shadow: ${modalShadowValue};
`

export const unstyledButton = css`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
`

export const dottedFocusOutline = css`
  outline: 1px dotted ${COLORS.WHITE};
`

export const keyboardFocusOutline = css`
  &:focus-visible {
    ${dottedFocusOutline}
  }
`

export const absoluteCentered = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const spinnerKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

export const spinningAnimationLinear = css`
  animation: ${spinnerKeyframes} 1.5s linear infinite;
`

const colorKeyframes = (color1: string, color2: string) => keyframes`
  0% {
    color: ${color1};
  }

  100% {
    color: ${color2};
  }
`

export const colorAnimation = (color1: string, color2: string) => css`
  animation: ${colorKeyframes(color1, color2)} 1.5s infinite alternate;
`

const opacityKeyFrames = (from: number, to: number) => keyframes`
  0% {
    opacity: ${from};
  }

  100% {
    opacity: ${to};
  }
`

export const opacityAnimation = (from: number, to: number) => css`
  animation: ${opacityKeyFrames(from, to)} 1.5s infinite alternate;
`

export const isScrollbarVisible = (ref: React.RefObject<HTMLElement>) => {
  if (ref.current) return ref.current.scrollHeight > ref.current.clientHeight
}

export const blueGradientStarBackground = css`
  ${media.below("sm")`
    background: url(${STARS_IMAGE_MOBILE}),
    linear-gradient(180deg, #202540 0%, #30226b 100%);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat
  `}
  ${media.above("sm")`
    background: url(${STARS_IMAGE}),
    linear-gradient(180deg, #1e2552 20.28%, #382d77 89.8%);
    background-size: contain;
    background-repeat: repeat;
    background-position: center center;
  `}
`

export const blueGradientBackground = css`
  ${media.below("sm")`
    background: linear-gradient(180deg, #202540 0%, #30226b 100%);
  `}
  ${media.above("sm")`
    background: linear-gradient(180deg, #1e2552 20.28%, #382d77 89.8%);
  `}
`

export const popupBorderValue = "1px solid #3e477c"

export const reactHotToastStyle = {
  background: "rgba(0, 0, 0, 0.7)",
  boxShadow: modalShadowValue,
  color: "white",
  fontSize: "13px",
  fontFamily: "DM Sans",
  fontWeight: 500,
}

export const modalBorderValue = `1px solid ${COLORS.BORDER}`

export const modalStyles = css`
  border: ${modalBorderValue};
  box-shadow: ${modalShadowValue};
`

export const pixelatedImage = css`
  image-rendering: pixelated;
  image-rendering: crisp-edges;
`

export const hoverOverlay = css`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.25s ease-in-out;
  z-index: 1;
`

export const rotationKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`

export const shakeAnimation = keyframes`
  10% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(-15deg);
  }

  30% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(15deg);
  }

  50% {
    transform: rotate(0deg);
  }`

export const StyledRotatingLoaderContainer = styled.img<{ width?: string }>`
  width: ${({ width }) => (isNil(width) ? "70px" : width)};
  animation: ${rotationKeyframes} 1s infinite ease;
`

// Using nowrap for some copy that is longer when translated (e.g. japanese)
export const nowrapForLongLocalizedText = css`
  white-space: nowrap;
`

export const gradientLavenderBackground = "linear-gradient(180deg, #000 0%, #333A64 100%)"

export const lineClamp = (numLines: number) => css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${numLines};
  overflow: hidden;
`
