import { css } from "@emotion/react"
import { CSSInterpolation } from "@emotion/serialize"

type BreakPoint = "sm" | "md" | "lg"

export const BREAKPOINT_VALUES: { [breakpoint in BreakPoint]: number } = {
  sm: 576,
  md: 768,
  lg: 992,
}

const breakpointToPixel = (bp: BreakPoint) => `${BREAKPOINT_VALUES[bp]}px`

const below =
  (bp: BreakPoint) =>
  (template: TemplateStringsArray, ...args: Array<CSSInterpolation>) =>
    css`
      @media (max-width: ${breakpointToPixel(bp)}) {
        ${css(template, ...args)}
      }
    `

const above =
  (bp: BreakPoint) =>
  (template: TemplateStringsArray, ...args: Array<CSSInterpolation>) =>
    css`
      @media (min-width: ${breakpointToPixel(bp)}) {
        ${css(template, ...args)}
      }
    `

const between =
  (bp1: BreakPoint, bp2: BreakPoint) =>
  (template: TemplateStringsArray, ...args: Array<CSSInterpolation>) =>
    css`
      @media (min-width: ${breakpointToPixel(bp1)}) and (max-width: ${breakpointToPixel(bp2)}) {
        ${css(template, ...args)}
      }
    `

export const media = {
  below,
  above,
  between,
}
