export enum Layers {
  Canvas,
  CanvasContextMenu,
  LeftBar,
  ObjectInteractionUI,
  VideoBackdrop,
  VideoUI,
  ActionBar,
  Toast,
  Modal,
  AwayFromTabOverlay,
  PriorityModal,
  Tooltip,
  ErrorModal,
}
