import { ThemeProvider } from "@emotion/react"
import Layout from "components/ui/Layout"
import LoadingScreen from "components/ui/LoadingScreen"
import React from "react"
import { DARK_THEME } from "styles/themes"

import { observer } from "gather-browser-common/dist/src/public/frontendMobxUtils"
import { Translated } from "../i18n/t"
import { COLORS } from "../styles/themes/colors"

interface Props {
  loadingText?: Translated
}

const RootRouteLoadingFallback: React.FC<Props> = observer(function RootRouteLoadingFallback({
  loadingText,
}) {
  return (
    <ThemeProvider theme={DARK_THEME}>
      <Layout
        width="100vw"
        height="100%"
        overflow="hidden"
        position="relative"
        backgroundColor={COLORS.DARK1}
      >
        <LoadingScreen loadingText={loadingText} />
      </Layout>
    </ThemeProvider>
  )
})

export default RootRouteLoadingFallback
