import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { UserDataFields } from "gather-http-common/dist/src/public/contracts/users/user"
import { DEFAULT_PATH_PARAM_USER } from "gather-http-common/dist/src/public/httpAPI"

export async function getUserDataField(
  field: (typeof UserDataFields)[keyof typeof UserDataFields],
) {
  return await tsrAPI.users.data.field.get({
    params: {
      userId: DEFAULT_PATH_PARAM_USER,
      field,
    },
  })
}

type UserDataUpdate =
  | Record<UserDataFields.name, string>
  | Record<UserDataFields.selectedLanguage, string>

export async function updateUserData(body: Partial<UserDataUpdate>) {
  return await tsrAPI.users.data.update({
    params: {
      userId: DEFAULT_PATH_PARAM_USER,
    },
    body,
  })
}
