import { GatherEventEmitter } from "gather-common-including-video/dist/src/public/GatherEventEmitter"
import { DesktopUpdateInfo } from "gather-electron-interop/dist/src/public/OptionalInterop"
import {
  BrowserUpdateInfo,
  isDefaultClientUpdate,
  isNotifiedClientUpdate,
} from "gather-http-common/dist/src/public/clientReleases"
import ElectronInterop from "src/ElectronInterop"
import { Logger } from "src/utils/Logger"

export enum ClientReleasesControllerEvent {
  BrowserClientUpdate = "BrowserClientUpdate",
  DesktopClientUpdate = "DesktopClientUpdate",
}

type ClientReleasesControllerEventDataMap = {
  [ClientReleasesControllerEvent.BrowserClientUpdate]: [BrowserUpdateInfo]
  [ClientReleasesControllerEvent.DesktopClientUpdate]: [DesktopUpdateInfo]
}

class ClientReleasesController extends GatherEventEmitter<
  ClientReleasesControllerEvent,
  ClientReleasesControllerEventDataMap
> {
  private constructor() {
    super()
  }

  static instance = new ClientReleasesController()

  public start() {
    const cleanupCallbacks = [
      ElectronInterop?.onDesktopUpdate
        ? ElectronInterop?.onDesktopUpdate((info) => {
            this.handleDesktopClientUpdate(info)
          })
        : () => {},
    ]
    return () => {
      cleanupCallbacks.forEach((f) => f())
    }
  }

  public handleBrowserClientUpdate(info: BrowserUpdateInfo) {
    if (!info.broken && !info.outdated) return

    Logger.log(
      `[Browser Client Status] Server told us our client is ${
        info.broken ? "broken" : "outdated"
      }!`,
      info,
    )
    // Don't prompt for updates under a certain threshold of criticality
    if (isNotifiedClientUpdate(info.level)) {
      this.publishEvent(ClientReleasesControllerEvent.BrowserClientUpdate, info)
    }
  }

  public handleDesktopClientUpdate(info: DesktopUpdateInfo) {
    Logger.log(`[Desktop Client Status] Desktop app requested to update`, info)
    // Don't prompt for updates under a certain threshold of criticality
    if (isDefaultClientUpdate(info.level) || isNotifiedClientUpdate(info.level)) {
      this.publishEvent(ClientReleasesControllerEvent.DesktopClientUpdate, info)
    }
  }
}

export default ClientReleasesController.instance
