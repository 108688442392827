import { pickBy } from "ramda"

import { getAllGateValuesForLogging } from "gather-browser-common/dist/src/public/gatekeeping/gate"
import { SpaceResource } from "gather-common/dist/src/public/resources/space"

export const getTrueGates = (
  userId: string | null,
  spaceId: string | null,
  currentSpaceInfo?: SpaceResource,
  spaceCreationDate?: Date,
) => {
  const gates = getAllGateValuesForLogging(
    userId,
    spaceId,
    // If the current space info isn't defined, we can't determine the space creation date,
    // so we pass in "NOT_AVAILABLE" to exclude gates that rely on this value.
    currentSpaceInfo ? spaceCreationDate : "NOT_AVAILABLE",
  )
  return Object.keys(pickBy((gateVal) => gateVal, gates))
}
