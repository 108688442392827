import Color from "color"

/*
=== Base colors ===

Avoid using these directly and instead try to use theme variables (eg. theme.button.primary.backgroundColor).
This will make these components themeable rather than fixed to a color.
*/

export const COLORS = {
  DARK0: "#545c8f",
  DARK1: "#333a64",
  DARK2: "#282D4E",
  DARK3: "#202540",
  DARK4: "#111111",
  DARK5: "#0F0F1D",

  GRAY0: "#ffffff",
  GRAY1: "#f2f2f2",
  GRAY2: "#e0e0e0",
  GRAY3: "#bdbdbd",
  GRAY4: "#979797",
  GRAY5: "#717171",

  BLUE0: "#ebf0ff",
  BLUE1: "#cad8ff",
  BLUE2: "#90adff",
  BLUE3: "#5882f7",
  BLUE4: "#4358d8",

  RED0: "#ff8090",
  RED1: "#ff3049",
  RED2: "#dd293f",

  GREEN: "#06d6a0",
  YELLOW: "#ffda56",
  ORANGE0: "#f78c6b",
  ORANGE1: "#ff7144",
  PINK0: "#ff7ee3",
  PINK1: "#ff98e8",

  // PURPLE0 is part of the new design system
  PURPLE0: "#BDB2F8",
  // PURPLE1 is PURPLE from the old design system
  PURPLE1: "#826ad7",

  LIGHT0: "#91ADFF",
  LIGHT1: "#EBF0FF",

  WHITE: "#ffffff",
  BLACK: "#000000",

  MENU: "#454d7b",

  BORDER: "#464B68",

  // TODO: https://linear.app/gather-town/issue/APP-5711/clean-up-ad-hoc-lavendar-colors-after-design-system-update
  LAVENDAR: "#202540",
}

export const colorWithAlpha = (color: string, alpha: number) => Color(color).alpha(alpha).toString()

export const whitenColor = (color: string, percent: number) =>
  Color(color)
    .mix(Color("white"), percent / 100)
    .hex()

export const blackenColor = (color: string, percent: number) =>
  Color(color)
    .mix(Color("black"), percent / 100)
    .hex()

export const lightenColor = (color: string, percent: number) =>
  Color(color)
    .lighten(percent / 100)
    .hex()

export const darkenColor = (color: string, percent: number) =>
  Color(color)
    .darken(percent / 100)
    .hex()

// "#ffffff" -> 0xffffff
export const getHexNumber = (color: string): number => Number(`0x${color.substring(1)}`)
